<template>
  <div>
    {{ administrators }}
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Tmp',
  mounted() {
    this.getAdministrators()
  },
  computed: {
    ...mapGetters('adminAuth', ['administrators'])
  },
  methods: {
    ...mapActions('adminAuth', ['getAdministrators']),
  }
}

</script>
